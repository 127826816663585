.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

.cta-slogan h5 {
	padding: 0px 0px 20px;
}

.contact {
	padding: 0px 0px !important;
}

.contact span {
	padding: 10px;
}

.contact i {
	color: rgb(0 110 142) !important;
    font-size: 16px;
    font-weight: 800;
    padding: 3px 3px;
}

.contact-icon {
	display: inline-block;
    background: white;
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

@include media( '>medium' ) {

	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}
