.cta {

    .form-input {
        border-color: transparent;
        border-radius: 2px;
    }
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
    background-color: get-color(primary, 3);
	background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;  
    border-radius: 24px;
    background-image: linear-gradient(to right top, #084287, #006999, #006f8b, #078338, #0cac07);
    color: white !important;
}

.cta-inner h5 {
	color: white !important;
}

@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 280px;
    }
}    

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}

// #4b5158