.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {
	.hero-avatar {
		width: 200px;
		height: 200px;
		max-width: 200px;
		max-height: 200px;
		margin: 0 auto;
		border-radius: 100px;
		background: url(https://media.licdn.com/dms/image/C5603AQFLtjH51V-GNg/profile-displayphoto-shrink_800_800/0/1648193866415?e=1699488000&v=beta&t=BFlo6NOJrws8uGaNVaYZ07V07VzPXKrfsNebG_nNArY);
		background-size: cover;
		background-position-x: 16px;
		margin-bottom: 40px;
	}
	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
